import React, {Fragment} from 'react';
import {ReactTicker} from '@guna81/react-ticker';
import {ServicesTab} from './home/Tabs';
import { IconUSA, IconWorld, IconCanada, IconEurope, IconOpenLink} from '../components/icons/Icons';
import {NavLink} from 'react-router-dom';
import CTAButton from '../components/CTAButton';
import BookingProcess from '../components/BookingProcess';

const HomeHeader = () => {
  return (
    <header id="content" role="main">
      <div>
        <h1>Realize your accessibility vision</h1>
          <br />
        <h3>Just as we care for all beings, an inclusive online presence reflects the commitment to your company's values and benefits your customers.</h3>
          <br />
        <p>Hello Accessible is a <u>digital accessibility</u> consulting firm in Vancouver, BC, serving all of <u>Canada and US</u>. Through offering expert audits, strategic services, and targeted trainings, we empower organizations to create accessible and intuitive user experiences.</p>
          <br /><br />
        <CTAButton />
      </div>
      <div>
        <video loop autoPlay muted playsInline aria-label="A man working on his laptop while petting his blind cat.">
          <source src="../../assets/stock-vid-2-edited.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </header>
  );
}

const data = [
  {
    id: 1,
    value:
      "In 2023, 8,227 lawsuits related to accessibility were filed in US federal court."
  },
  {
    id: 2,
    value:
      "90% of websites are inaccessible to people with disabilities who use assistive technologies."
  },
  {
    id: 3,
    value:
      "By 2060, the number of people 65 or older in USA is expected to double to 98 million."
  },
];

const HomeTicker = () => {
  return (
    <ReactTicker
      data={data.map((item) => item.value)}
      speed={50}
      keyName="_id"
      tickerClassName="news-ticker"
    />
  );
}

const HomeServices = () => {
  return (
    <section className="page-section" id="services" role="contentinfo">
      <div className="page-section-heading">
        <div>
          <h5>Your one-stop shop for inclusive experiences</h5>
          <br />
          <h2>Our Digital Accessibility Solutions</h2>
        </div>
      </div>
      <ServicesTab />
    </section>
  );
}

const HomeCTA = () => {
  return (
    <section id="cta" role="contentinfo">
      <div>
        <video loop autoPlay muted playsInline aria-label="A hand using a braille keyboard.">
          <source src="../../assets/stock-vid-3-edited.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div>
        <h4>Not sure what you need?</h4>
        <p>Speak to our expert today by booking a free consultation call. In our 30 minute consultation, we will learn about your business's existing accessibility challenges and explore our path forward together.</p>
        <br />
        <CTAButton />
      </div>
    </section>
  );
}

const HomeLaws = () => {
  return (
    <section id="laws-marquee" role="contentinfo">
        <div className="page-section-heading">
          <div>
            <h5>For your peace of mind</h5>
            <br />
            <h2>Stay ahead of evolving laws</h2>
            <br />
            <p>
              In 2023, 8,000+ lawsuits were filed in federal court under Title III of the ADA. Navigating the complex landscape of accessibility laws and regulations can be daunting, but it doesn't have to be. At Hello Accessible, we stay ahead of the curve by constantly monitoring and interpreting the latest legal requirements, ensuring your business remains compliant and inclusive. Our dedicated team of experts provides tailored guidance and practical solutions, giving you the peace of mind to focus on what you do best. With our support, you can confidently meet and exceed accessibility standards, fostering an environment where everyone feels welcome and valued.
            </p>
          </div>
        </div>
        <article>
          <div className="grid">
            <NavLink>
              <IconWorld /> WCAG
            </NavLink>
            <NavLink>
              <IconUSA /> ADA
            </NavLink>
            <NavLink>
              <IconUSA /> Sect. 508
            </NavLink>
            <NavLink>
              <IconCanada /> ACA
            </NavLink>
            <NavLink>
              <IconEurope /> EAA
            </NavLink>
            <NavLink>
              <IconEurope /> EN 301 549
            </NavLink>
            <NavLink>
              <IconCanada /> AODA
            </NavLink>
            <NavLink>
              & More
            </NavLink>
          </div>
        </article>
    </section>
  );
}
 
const Home = () => {
  return (
    <Fragment>
      <HomeHeader />
      <HomeTicker />
      <hr />
      <HomeServices />
      <hr />
      <HomeLaws />
      <hr />
      <BookingProcess />
      <hr />
      <HomeCTA />
    </Fragment>
  );
}

export default Home;
