import React, {Fragment} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { 
  IconAudit, IconDocs, IconTrainings, IconVpat, IconRemediation, IconCustom,
  IconUSA, IconWorld, IconCanada, IconEurope,
  IconArrow
} from '../../components/icons/Icons';
import {NavLink} from 'react-router-dom';

const colorDark = "#2D2D2D";

const TabButton = props => {
  return (
    <Fragment>
      <div>
        <div>
          {props.icon}
          {props.title}
        </div>
        {props.arrow ? <IconArrow /> : <Fragment />}
      </div>
      {/* {props.description ? <p className="hidden-mobile">{props.description}</p> : <Fragment />} */}
    </Fragment>
  );
}

const LawTabContent = props => {
    return (
      <div>
        <div>
          <h3>{props.abbreviation} • {props.year} • {props.country}</h3>
          <p>{props.fullName}</p>
          <p>{props.children}</p>
        </div>
      </div>
    );
}

export const ServicesTab = () => {
  const Audit = "Manual and comprehensive audit of your website for compliance by our certified auditors.";
  const VPAT = "Prepare official documents to demonstrate compliance and validate accessibility claims.";
  const Docs = "Convert and create certified accessible PDFs, Word documents, and other digital files.";
  const Trainings = "Empower your team with expert-led sessions on practical skills to ensure compliance.";
  const Remediation = "Deep dive into your code or files to fix your accessibility issues in your website or designs.";
  const Custom = "Tailored accessibility solutions to meet your unique needs as they show up.";

  const TabContent = props => {
    return (
      <div className="tab-content">
        <h4>{props.title}</h4>
        <br />
        <p>{props.description}</p>
        <img className="tab-images" src={props.imageSrc} alt={props.alt} />
        <br />
        <NavLink to={props.buttonLink} className="button">
          {props.buttonTitle}
        </NavLink>
      </div>
    )
  }

    return (
        <Tabs className="react-tabs page-section-content">
          <TabList>
            <Tab><TabButton icon={<IconAudit color={colorDark} />}  arrow={true} title="Audits" description={Audit}/></Tab>
            <Tab><TabButton icon={<IconRemediation color={colorDark} />} arrow={true} title="Remediation" description={Remediation} /></Tab>
            <Tab><TabButton icon={<IconTrainings color={colorDark} />} arrow={true} title="Trainings" description={Trainings} /></Tab>
            <Tab><TabButton icon={<IconVpat color={colorDark}/>} arrow={true} title="VPAT" description={VPAT}/></Tab>
            <Tab><TabButton icon={<IconDocs color={colorDark} />} arrow={true} title="PDFs & Docs" description={Docs} /></Tab>
            <Tab><TabButton icon={<IconCustom color={colorDark} />} arrow={true} title="Custom" description={Custom} /></Tab>
          </TabList>
          <div>
            <TabPanel>
              <TabContent
                title="Digital Accessibility Audits"
                description="Our certified accessibility experts will conduct a thorough manual evaluation of your digital assets during an accessibility audit. We consistently provide our clients with a comprehensive report to ensure compliance with standards for websites, applications, and designs."
                imageSrc="../../../assets/report.png"
                alt="Example of an audit report."
                buttonLink="/contact"
                buttonTitle="Request an audit"
              />
            </TabPanel>
            <TabPanel>
              <TabContent
                title="Remediation Services"
                description="We provide comprehensive remediation services to fix accessibility issues across your digital assets, ensuring compliance with WCAG and other accessibility standards. Our team identifies and resolves barriers in websites, apps, documents, and other digital products, making them fully usable for individuals with disabilities."
                imageSrc=""
                alt=""
                buttonLink="/contact"
                buttonTitle="Learn more"
              />
            </TabPanel>
            <TabPanel>
              <TabContent
                title="Trainings & Workshops"
                description="We offer customized trainings and workshops to help your team understand and implement digital accessibility best practices. Our sessions cover topics such as WCAG compliance, accessible design, and inclusive development, equipping your staff with practical skills to create and maintain accessible digital content."
                imageSrc=""
                alt=""
                buttonLink="/contact"
                buttonTitle="Learn more"
              />
            </TabPanel>
            <TabPanel>
              <TabContent
                title="VPAT & ACR Preparation"
                description="We help organizations prepare a Voluntary Product Accessibility Template (VPAT) and Accessibility Conformance Report (ACR) to ensure their digital products meet accessibility standards. This includes evaluating compliance with WCAG, Section 508, and other relevant guidelines, enabling your business to clearly communicate product accessibility to clients and customers."
                imageSrc=""
                alt=""
                buttonLink="/contact"
                buttonTitle="Learn more"
              />
            </TabPanel>
            <TabPanel>
              <TabContent
                title="Accessible PDFs & Docs"
                description="We ensure your PDFs and documents are fully accessible, making them compliant with WCAG and PDF/UA standards. This includes proper tagging, alt text, logical reading order, and other features that enable screen reader compatibility and easy navigation for users with disabilities."
                imageSrc=""
                alt=""
                buttonLink="/contact"
                buttonTitle="Learn more"
              />
            </TabPanel>
            <TabPanel>
              <TabContent
                title="Customised Solutions"
                description="We deliver tailored accessibility solutions based on your specific needs and digital environment. Our approach is flexible and designed to address your unique challenges, ensuring full compliance with accessibility standards while optimizing user experience for all."
                imageSrc=""
                alt=""
                buttonLink="/contact"
                buttonTitle="Learn more"
              />
            </TabPanel>
          </div>
        </Tabs>
    );
}

export const LawTabs = () => {
  return (
    <Tabs>
      <TabList>
        <Tab><TabButton icon={<IconWorld />} title="WCAG" /></Tab>
        <Tab><TabButton icon={<IconUSA />} title="ADA" /></Tab>
        <Tab><TabButton icon={<IconUSA />} title="Section 508" /></Tab>
        <Tab><TabButton icon={<IconCanada />} title="ACA" /></Tab>
        <Tab><TabButton icon={<IconEurope />} title="EAA" /></Tab>
        <Tab><TabButton icon={<IconEurope />} title="EN 301 549" /></Tab>
        <li>
          <NavLink to="/services">
            More
          </NavLink>
        </li>
      </TabList>
      <TabPanel>
        <LawTabContent
          abbreviation="WCAG"
          year="1999"
          country="Worldwide"
          fullName="Web Content Accessibility Guidelines"
        >
          The Web Content Accessibility Guidelines (WCAG) are a set of standards designed to ensure digital content is accessible to all users, including those with disabilities. 
          <br />
          <ol>
            <li><b>Perceivable</b>: Content must be presented in ways that users can perceive.</li>
            <li><b>Operable</b>: Interface and navigation must be operable by all.</li>
            <li><b>Understandable</b>: Information and operation of the interface must be understandable.</li>
            <li><b>Robust</b>: Content must be robust enough to be interpreted by a wide variety of user agents, including assistive technologies.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
      <TabPanel>
        <LawTabContent
          abbreviation="ADA"
          year="1990"
          country="USA"
          fullName="Americans with Disabilities Act"
        >
          The Americans with Disabilities Act (ADA) is a civil rights law that prohibits discrimination against individuals with disabilities in all areas of public life, including jobs, schools, transportation, and all public and private places open to the general public. 
          <br />
          <ol>
            <li><b>Employment</b>: Employers must provide reasonable accommodations for employees with disabilities.</li>
            <li><b>Public Services</b>: State and local government services must be accessible.</li>
            <li><b>Public Accommodations</b>: Businesses must ensure their facilities and services are accessible.</li>
            <li><b>Telecommunications</b>: Providers must offer relay services for those with hearing or speech disabilities.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
      <TabPanel>
        <LawTabContent
            abbreviation="Section 508"
            year="1986"
            country="USA"
            fullName="Section 508"
        >
          Section 508 of the Rehabilitation Act requires federal agencies to make their electronic and information technology (EIT) accessible to people with disabilities. 
          <br />
          <ol>
            <li><b>Electronic Content</b>: Websites, online training, and other digital content must be accessible.</li>
            <li><b>Software Applications</b>: Applications must be usable by people with various disabilities.</li>
            <li><b>Hardware</b>: Physical devices, like computers and phones, must be accessible.</li>
            <li><b>Support Documentation and Services</b>: All support and documentation must be accessible.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
      <TabPanel>
        <LawTabContent
          abbreviation="ACA"
          year="2018"
          country="Canada"
          fullName="Accessible Canada Act"
        >
          The Accessible Canada Act (ACA) is a federal law aimed at making Canada barrier-free by January 1, 2040, for individuals with disabilities. 
          <ol>
            <li><b>Employment</b>: Ensuring workplace accessibility and inclusive hiring practices.</li>
            <li><b>Built Environment</b>: Making buildings and public spaces accessible.</li>
            <li><b>Information and Communication Technologies</b>: Ensuring digital content and technologies are accessible.</li>
            <li><b>Procurement</b>: Mandating accessible products and services in government procurement.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
      <TabPanel>
        <LawTabContent
          abbreviation="EAA"
          year="2019"
          country="Europe"
          fullName="European Accessibility Act"
        >
          The European Accessibility Act (EAA) is a directive aimed at improving the accessibility of products and services in the European Union for people with disabilities. 
          <ol>
            <li><b>Products</b>: Ensures accessibility of ATMs, ticketing machines, smartphones, computers, TVs, and e-books.</li>
            <li><b>Services</b>: Covers banking, e-commerce, transport, and telecommunication services.</li>
            <li><b>Harmonization</b>: Aligns accessibility requirements across EU member states to facilitate the internal market.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
      <TabPanel>
        <LawTabContent
          abbreviation="EN 301 549"
          year="2019"
          country="Europe"
          fullName="EN 301 549"
        >
          EN 301 549 is a European standard for accessibility requirements for ICT products and services, ensuring they are usable by people with disabilities.
          <ol>
            <li>ICT Products: Covers a wide range of digital devices and software, including computers, smartphones, websites, and mobile applications.</li>
            <li>Public Procurement: Applicable to public sector organizations for procuring accessible ICT products and services.</li>
            <li>Harmonization: Aligns with the Web Content Accessibility Guidelines (WCAG) to create a consistent approach across Europe.</li>
          </ol>
        </LawTabContent>
      </TabPanel>
    </Tabs>
  );
}