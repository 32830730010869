import React, {Fragment} from 'react';

const Accessibility = () => {
  return (
    <section className="page-section" id="terms">
      <div className="page-section-heading">
        <div>
          <h2>Accessibility Statement</h2>
          <h3></h3>
        </div>
      </div>
      <p>
        At Hello Accessible, we are committed to making the digital world accessible to everyone. Our mission is to help organizations identify, remove, and prevent digital barriers, ensuring equal access to information and services for people of all abilities.
      </p>
      <br />
      <p>
        As experts in digital accessibility, we understand the diverse needs of users, including those with disabilities. We follow best practices and strive to meet or exceed the standards outlined in the Web Content Accessibility Guidelines (WCAG) 2.1, Level AA. Our website is continually monitored and updated to enhance its accessibility.
      </p>
      <br />
      <h3>Feedback and Support</h3>
      <p>
        We value your feedback. If you encounter any accessibility issues or need assistance accessing any part of our website, please email us. We are committed to providing information in alternative formats upon request and will respond to all inquiries as soon as possible.
      </p>
      <br />
      <p>Together, we can create a more inclusive digital environment.</p>
      <br />
      <p><a href="mailto:support@helloaccessible.com">support@helloaccessible.com</a></p>
      <br />
      <h3>Our Commitment</h3>
      <ul>
        <li>• Compliance with WCAG 2.1 Level AA.</li>
        <li>• Regular reviews and updates to improve accessibility.</li>
        <li>• Collaboration with clients to ensure their digital assets meet the highest accessibility standards.</li>
      </ul>
    </section>
  );
}

export default Accessibility;
