import React, {Fragment} from 'react';
import {IconOpenLink} from '../components/icons/Icons'; 
 
const About = () => {
  return (
    <Fragment>
      <section className="about-page">
        <h2>About Us</h2>
        <div className="about-header">
          <div>
            <h4>Who are we?</h4>
            <p>Hello Accessible is a leading provider of all things accessibility, dedicated to empowering individuals and organisations to create inclusive digital experiences. </p>
          </div>
          <div>
            <h4>What do we do?</h4>
            <p>Our mission is to bridge the gap between technology and inclusivity, enabling companies, regardless of their abilities, to access and engage with digital content.</p>
          </div>
          <div>
            <h4>How do we do it?</h4>
            <p>We specialise in helping organisations navigate the complexities of accessibility compliance and implementation through a range of holistic services, including digital accessibility audits, remediation services, customised training programs, and VPAT document preparation to help organisations comply with WCAG standards and accessibility laws.</p>
          </div>
          <div>
            <h4>Why do we do it?</h4>
            <p>Hello Accessible is founded on the belief that accessibility is not only a legal requirement but also a moral imperative and a significant business opportunity. By making digital spaces accessible, we unlock value for our clients, enabling them to reach a broader audience and foster greater customer loyalty, and ensure that all customers can access the same content and services for a more equitable experience.</p>
          </div>
          <div>
            <h4>Why you?</h4>
            <p>We differentiate ourselves from competitors by combining technical expertise with a focus on practical, actionable solutions, ensuring that accessibility improvements are realistic, sustainable, and effective.</p>
          </div>
        </div>
        <br /><br />
        <h2>Team</h2>
        <div className="about-team">
          <div className="about-team-content">
            <div>
              <div className="about-team-photos">
                <img src="../../../assets/founder.jpeg" alt="Photo of Hello Accessibility's founder" />
              </div>
              <div className="about-team-name">
                <h4>Patricia Goh</h4>
                <h6><i>(she/her)</i></h6>
              </div>
              <h5>Founder</h5>
              <br />
              <p>With over 8 years of global experience in software engineering and leadership, I specialize in building high-performing teams and driving strategic initiatives. As the founder of Hello Accessible, I am dedicated to helping organisations create accessible digital experiences. My mission is to bridge the gap between technology and inclusivity, ensuring that all individuals, regardless of ability, have a way to engage with the digital world. For me, true accessibility is about not being an afterthought - it is baked in from the start, so we can build a future where technology empowers everyone.</p>
            </div>
            <div>
              <div className="about-team-photos">
                <img src="../../../assets/head-of-purrfessional-wellness.jpeg" alt="Photo of Hello Accessibility's emotional support cat" />
              </div>
              <div className="about-team-name">
                <h4>Kafka</h4>
                <h6><i>(he/him)</i></h6>
              </div>
              <h5>Chief Purr-fessional Wellness Officer</h5>
              <br />
              <p>As Hello Accessible's fluffy morale booster and resident cuddle connoisseur, I bring a natural curiosity and keen eye for detail. Through the years hunting down bugs, I have mastered the art of spotting "invisible" details, just like our accessibility experts! I take my role very seriously, ensuring my team takes stretch breaks and stay paw-sitive. Known for my attentiveness, I also occasionally "test" my team's work by walking across their keyboards. My presence reminds my team that even the smallest gestures can make a big impact, just like the accessibility work we do that make our digital world more inclusive.</p>
            </div>
            <div>
              <div className="about-team-photos" />
              <div className="about-team-name">
                <h4>This could be you</h4>
              </div>
              <h5>Your Title</h5>
              <br />
              <p>We're always looking for talented and passionate professionals to join us in our mission. Please reach out to us with your resumé and let's start the conversation!</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default About;
