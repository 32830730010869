import React, {Fragment} from 'react';
import {IconCall, IconMessage, IconPin, IconOpenLink} from '../components/icons/Icons'; 
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Form from '../components/Form';

const Contact = () => {
  return (
    <Fragment>
        <section className="contact" role="contentinfo">
            <div className="page-section">
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconMessage />
                        <h4>Chat with us</h4>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Our friendly team is here to help.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6><a href="mailto:support@helloaccessible.com">support@helloaccessible.com</a></h6>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconCall />
                        <h4>Call us</h4>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>We're always happy to connect.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6>
                            <a href="https://calendly.com/helloaccessible" target="_blank" aria-label="Link opens in a new window" className="link-new-tab">Book a call <IconOpenLink /></a>
                        </h6>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconPin />
                        <h4>Visit us</h4>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Come say hello at our office.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6>1575 W Georgia St, Vancouver, BC</h6>
                    </div>
                </div>
            </div>
            <div className="contact-right-column">
                <h2>Reach out to our team</h2>
                <h3>Let's make a difference together.</h3>
                <Form />
            </div>
        </section>
        <hr />
        <section className="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21803.13424774351!2d-123.1460601295417!3d49.30110261379473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486718636aadcf3%3A0x7a655bbf397452c8!2s1575%20W%20Pender%20St%2C%20Vancouver%2C%20BC%20V6G%202T1%2C%20Canada!5e0!3m2!1sen!2smy!4v1729328915764!5m2!1sen!2smy" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
        </section>
        {/* <hr /> */}
        {/* <section className="faq page-section" role="contentinfo">
            <h2>Frequently Asked Questions</h2>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            First Button
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        1
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Second Button
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        2
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Third Button
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        3
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </section> */}
    </Fragment>
  );
}

export default Contact;
